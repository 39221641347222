import AppConfig from '@/config/app';

export default {
  name: 'Footer',

  data() {
    return {
      appName: AppConfig.NAME,
    };
  },
};
