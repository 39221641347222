import AppConfig from '@/config/app';

export default {
  name: 'FooterSimple',

  data() {
    return {
      appName: AppConfig.NAME,
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
