import Tabs from '@/components/General/Tabs';
import FooterAdvanced from '@/components/General/FooterAdvanced';
import FooterSimple from '@/components/General/FooterSimple';
import Vue from 'vue';

export default Vue.extend({
  name: 'Main',

  components: { Tabs, FooterAdvanced, FooterSimple },

  props: {
    footerType: {
      type: String,
      required: false,
      default: 'advanced',
    },
  },
});
