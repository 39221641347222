import Api from '@/utils/api';
import UsersTransformers from '@/transformers/users';
import { MODULE_NAME as AUTH_MODULE, ACTIONS as AUTH_ACTIONS } from '@/store/modules/auth/types';
import store from '@/store/index';

export const login = async (email, password) => {
  try {
    const response = await Api.post(`/auth/login`, { email, password });

    const user = { ...response.data, ...{ email: email } };

    localStorage.setItem('USER', JSON.stringify(user));

    store.dispatch(`${AUTH_MODULE}/${AUTH_ACTIONS.SIGN_USER_IN}`, user);

    return user;
  } catch (error) {
    console.log(`Api error when calling /auth/login`, error);
  }

  return null;
};

export const register = async (name, email, password) => {
  try {
    const response = await Api.post(`/auth/register`, { name, email, password });

    return response.data;
  } catch (error) {
    console.log(`Api error when calling /auth/register`, error);
  }

  return null;
};

export const getTop6 = async () => {
  try {
    const response = await Api.get(`/users/top-6`);

    return UsersTransformers(response.data);
  } catch (error) {
    console.log(`Api error when calling /users/top-6`, error);
  }

  return null;
};

export const forgotPassword = async (email) => {
  try {
    const response = await Api.post(`/auth/forgot-password`, { email });

    return response;
  } catch (error) {
    console.log(`Api error when calling /auth/forgot-password`, error);
  }

  return null;
};

export const logout = async () => {
  try {
    const response = await Api.post(`/auth/logout`);

    return response;
  } catch (error) {
    console.log(`Api error when calling /auth/logout`, error);
  }

  return null;
};
