import { MODULE_NAME as AUTH_MODULE, GETTERS as AUTH_GETTERS } from '@/store/modules/auth/types';
import { mapGetters } from 'vuex';

export default {
  name: 'Tabs',

  data() {
    return {
      isMobileMenuOpen: false,
    };
  },

  computed: {
    ...mapGetters(AUTH_MODULE, {
      loggedUser: AUTH_GETTERS.USER,
      isLoggedIn: AUTH_GETTERS.IS_LOGGED_IN,
    }),
  },

  methods: {
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
  },
};
